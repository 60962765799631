.ItemList {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: 1rem auto;
    max-width: 1244px;
  }

  @media (max-width: 1080px) {
    .ItemList {
      margin: 1rem 1%;
    }
  }