.navbar-toggle {
    display: none;
}

.mobile-header{
    display: none;
}

/* Desktop */
.desktop-header { 
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid  #e9e7e7;
    border-top: 2px solid  #e9e7e7;
    box-shadow: 0 0px 8px rgb(0 0 0 / 30%);
    height: 70px;
}

.head-container {
    width: 90%;   
    margin-right: auto;
    margin-left: auto;
}

.navlink{
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.nav-list{
  display: flex;
}

.nav-list li {
  margin-right: 20px;
  width: 100%;
}
.width {
  width: 100%;
  height: 100%;
}


.header-holder {
    display: flex;
    justify-content: space-between; 
    width: 100%;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    -webkit-align-items: center;
    -ms-flex-align: center;
}

.cart {
    position: relative;
}
  
  .cart span {
      position: absolute;
      top: -5px;
      right: 0px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      text-align: center;
      background-color: #e94560;
      font-size: 12px;
      color: #fff;
  }

  .cartMenu{
    position: fixed;
    display: inline-block;
    list-style: none;
    box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    right: 0;
    top: 0;
    width: 30%;
    height: 100vh;
    background: white;
    z-index: 9999;
    animation: slideInRight .5s forwards;
    min-width: 150px;
  }
    .cartClose button{
      margin: 65px 20px 0px auto;
      
    }
  @keyframes slideInRight {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0);
    }
  }
/* Desktop */
/* Mobile   */
@media (max-width: 720px) {
    .desktop-header{
        display: none;
    }

    .mobile-header {
        display: block;
        border-bottom: 2px solid  #82954B;
        box-shadow: 0 0px 8px rgb(0 0 0 / 30%)
    }
    .container-fluid{
      padding: 10px 5px 5px 5px;
    }
    .mobile-navbar{
      
    }
    .nav-links-MobileMenu {
        position: fixed;
        display: block;
        list-style: none;
        box-shadow: rgba(50, 50, 93, 0.23) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        left: 0;
        top: 0;
        width: 30%;
        height: 100vh;
        background: white;
        z-index: 1000;
        animation: slideInLeft .5s forwards;
        min-width: 150px;
    }
    @keyframes slideInLeft {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(0);
      }
    }
    .close{
      margin: 65px 0px 0px 7px;
    }
    .top{
      margin: 5px 0px 0px 15px;
    }
    .bottom{
      border-top: 1px solid #e9e7e7; 
      position: absolute;
      bottom: 0;
      margin: 5px 0px 40px 15px;
    }
    .nav-links-MobileMenu li{
        display: flex;
        padding-top: 10px;
        font-size: 14px;
        text-decoration: none;
        color: #191919;
    }

    .header-row {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-align-content: flex-start;
      -ms-flex-line-pack: start;
      align-content: flex-start;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      min-height: 40px;
    }

    .middle {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    .mobile-parent-menu {
      width: 50px;
    }

    .mobile-header .top-line {
      border-top: 1px solid #e9e7e7; 
      border-bottom: 1px solid #e9e7e7; 
    }

    .logo-container {
        padding: 10px;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-align-content: center;
        -ms-flex-line-pack: center;
        align-content: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        width: 100%;
    }

    .row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -10px;
      margin-left: -10px;
    }

    .logo img{
      display: inline-block;
    }

    header ul {
      padding-top: 30px;
    }
  
    header ul li {
      width: 100%;
      text-transform: uppercase;
    }
  
    header .link {
      display: none;
    }
  
    .navbar-toggle {
      display: block;
      font-size: 25px;
      z-index: 999;
    }
  
    .header.active {
      height: 10vh;
    }

    .icon-circle{
      margin-left: 5px;
    }

    .search{
      border: 2px solid  #e9e7e7;
      max-width: 200px;
      display: flex;
      padding: 7px 0px;
    }
      input[type="search"]::-webkit-search-cancel-button {
        -webkit-appearance: none;
      }
      .searchbar{
        max-width: 140px;
      }
      .search i {
        margin-right: 5px;
      }
}  
/* Mobile */


