.Item {
    display: block;
    width: 19rem;
    margin: 0 .5rem 2.3rem .5rem;
    text-align: center;
    align-items: center;
    border: 3px solid #e3dddd;
    min-width: 250px;
  }
  
  .Item .image-container {
    height: 16.66rem;
    margin-bottom: 1rem;
  }
  
  .Item h2 {
    margin-bottom: 10px;
    font-size: 1.5rem;
  }
  
  .Item-price {
    margin-bottom: 10px;
  }

  .add-item{
    margin-bottom: 10px;
    display: block;
  }
  
  @media (max-width: 1080px) {
    .Item{
      
    }
  }

  
