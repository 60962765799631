.item{
    margin-bottom: .5rem;
}
    .input-container{
        position: relative;
        margin: 0 auto ;

        max-width: 132px;
        min-width: 132px;
    }
        .minus-btn{
            left: 0px;
        }
        .plus-btn{
            right: 0px;
        }
        .input-container button{
            display: inline-block;
            font-size: 27px;
            line-height: 1em;
            cursor: pointer;
            position: absolute;
            width: 49px;
            height: 100%;
            text-align: center;
            top: 0;
            color: #d8d8d8;
        }
        .input-container input{
            background: #f7f8fa;
            display: block;
            width: 100%;
            height: 50px;
            font-size: 14px;
            line-height: 1em;
            text-align: center;
            border: none;
            outline: none;
            font-family: 'Archivo', sans-serif;
            font-weight: 500;
            color: #333333; 
        }

.add-container{
    text-align: center;
}

    .add-to-cart {
        position: relative;
        display: inline-block;
        background: #3e3e3f;
        color: #fff;
        border: none;
        border-radius: 0;
        padding: 1.25rem 2.5rem;
        font-size: 1rem;
        text-transform: uppercase;
        cursor: pointer;
        transform: translateZ(0);
        transition: color 0.3s ease;
        letter-spacing: 0.0625rem;
    }

    .add-to-cart:hover::before {
    transform: scaleX(1);
    }

    .add-to-cart::before {
        position: absolute;
        content: '';
        z-index: -1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #565657;
        transform: scaleX(0);
        transform-origin: 0 50%;
        transition: transform 0.3s ease-out;
    }


