.header {
  width: 100%;
  background-color: white;
}

.header ul {
  
  
}

.header ul li a {
  font-size: 15px;
  font-weight: 400;
}


