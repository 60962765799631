.suggestedItems{
    width: 90%;
    text-align: center;
    margin: 0 auto;
    max-width: 1250px;
}
.suggestedItems .header{
    margin: 10px 0;
}
.suggestedItem{
    max-width: 250px;
    margin: 0 auto !important;
    display: block;
    text-align: center;
    align-items: center;
    border: 3px solid #e3dddd;
    min-width: 250px;
}
    .suggestedItem .image-container {
        height: 16.66rem;
        margin-bottom: 1rem;
    }
    
    .suggestedItem h2 {
        margin-bottom: 10px;
        font-size: 1.5rem;
    }
.products-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

@media (max-width: 1080px) {
    .suggestedItems{
        height: 450px;
       
    }
    .products-container{
        position: absolute;
        flex-wrap: nowrap;
        overflow: hidden;
        
    }
}
.row{
    display:flex;
}
.MultiCarousel { float: left; overflow: hidden; padding: 15px; width: 100%; position:relative; }
    .MultiCarousel .MultiCarousel-inner { transition: 1s ease all; float: left; display:flex;}
        .MultiCarousel .MultiCarousel-inner .item { float: left;}
        .MultiCarousel .MultiCarousel-inner .item > div { text-align: center; padding:10px; margin:10px; background:#f1f1f1; color:#666;}
    .MultiCarousel .leftLst, .MultiCarousel .rightLst { position:absolute; border-radius:50%;top:calc(50% - 20px); }
    .MultiCarousel .leftLst { left:0; }
    .MultiCarousel .rightLst { right:0; }
    
        .MultiCarousel .leftLst.over, .MultiCarousel .rightLst.over { pointer-events: none; background:#ccc; }