@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}
div{
  display: block;
}
body {
  font-family: 'Pontano Sans', sans-serif;
  font-size: 1.125rem;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  color: #888;
  background: #fff;
  text-rendering: optimizeLegibility;
}

.hover:hover{
  color: #EFD345;
  cursor: pointer;
}

.background:hover{
  background-color: #3d3e3f;
}

a {
  text-decoration: none;
  color: black;
}

li {
  list-style: none;
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

input,
button {
  border: none;
  outline: none;
  background-color: none;
}

button{
  background: none;
  color: black;
}

.hidden{
  display: none;
}

.flex {
  display: flex;
}
.indent{
  border-bottom: 0.0625rem solid #e3dddd;
  margin: 25px 0;
}
.center {
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align-last: center;
}

.icon-circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #f3f5f9;
  margin-left: 20px;
  text-align: center;
  border-radius: 50%;
}

.overlay{
  background-color: rgba(0, 0, 0, 0.55);
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 21;
}
.sticky{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background-color: white;
}