.search-bar{
  
  z-index: 22;
}
  .search-bar-container{

  }

    .search{
      margin: auto 0px;
    }

      .search-box {
        display: flex;
        border: 1px solid rgb(186, 189, 66);
        padding: 10px 0px;
      }

        .search-box input {

        }

          .search-box i  {
            text-align: center;
            opacity: 0.5;
            
            font-size: 17px;
            border-left: 2px solid rgb(186, 189, 66);
          }

          .search-box button {
            width: 5%;
          }

@media (max-width: 1080px) {

}
