.image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    background: #000;
  }
  
  .image-container img {
    width: 100%;
  }

  .bread-container{
    margin: 20px auto;
    width: 100%;
    width: 85%; 
  }

  .breadcrumb-list {  
    display: flex;
    flex-wrap: wrap;
    list-style: none;
  }
  .breadcrumb-list li {
    font-size: 0.85rem;
    letter-spacing: 0.125rem;
    text-transform: uppercase;
  }
  .breadcrumb-item.active {
    color: #333;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: '/';
    display: inline-block;
    padding: 0 0.5rem;
    color: #d5d5d5;
  }
  
  .breadcrumb-item.active {
    color: #333;
  }
  .breadcrumb-item + .breadcrumb-item::before {
    content: '/';
    display: inline-block;
    padding: 0 0.5rem;
    color: #d5d5d5;
  }