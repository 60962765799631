.slider {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transform: translateX(-50%);
  transition: all 0.5s ease;
}

.slide-button{
  display:inline-block;
  padding:0.3em 1.2em;
  margin:0 0.3em 0.3em 0;
  border-radius:2em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-weight:300;
  color:#FFFFFF;
  background-color:#4eb5f1;
  text-align:center;
  transition: all 0.2s;
}

@media screen and (min-width: 600px) {
  .slide img {
    width: 100%;
    height: 100%;
  }
}

.slide img {
  /* // width: 100%; */
  margin-left: auto;
  max-width: 1550px;
  height: 100%;
}

.current {
  opacity: 1;
  transform: translateX(0);
}

.content {
  z-index: 9999;
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translate(-50%, 0) !important;
  opacity: 0;
  width: 35%;
  max-width: 350px;
  min-width: 150px;
  color: #fff;
  padding: 5px;
  background: rgba(0, 0, 0, 0.3);
  animation-fill-mode: forwards;
  font-size: 14px;
  text-align: center;
}
.content button:hover{
  color: #EFD345;
}

@keyframes slide-up {
  0% {
    visibility: visible;
    top: 23rem;
  }
  100% {
    visibility: visible;
    top: 17rem;
  }
}

.content > * {
  color: #fff;
  margin-bottom: 1rem;
}

.current .content {
  opacity: 1;
  transform: translateX(0);
  transition: all 0.5s ease;
}

.arrow {
  border: 2px solid white;
  background-color: #82954B;
  color: #fff;
  cursor: pointer;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  position: absolute;
  z-index: 50;
}
.arrow:hover {
  background-color: #EFD345;
  color: #777;
}

.next {
  top: 35%;
  right: 3rem;
}
.prev {
  top: 35%;
  left: 3rem;
}

hr {
  height: 2px;
  background: white;
  width: 50%;
}

@media (max-width: 1080px) {
  .content {
    width: 260px;
  }
  .slide {
    max-height: 475px;
  }
  .arrow{
    display: none;
  }
}

