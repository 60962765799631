@import url('https://fonts.googleapis.com/css?family=Pontano+Sans');
.iteminfo{
  font-family: 'Pontano Sans', sans-serif;
  margin: 0;
  padding: 0;
  color: #888;
  background: #fff;
  text-rendering: optimizeLegibility;
}

.iteminfo-container h1, .iteminfo-container h2, 
.iteminfo-container h3, .iteminfo-container h4 {
  color: #333;
  font-weight: normal;
  text-align: center;
}
.iteminfo-container h1 {
  font-size: 2.5rem;
}
.iteminfo-container h2 {
  font-size: 2.125rem;
  margin: 0;
}
.iteminfo-container h3 {
  font-size: 2rem;
}
.iteminfo-container h4 {
  font-size: 1.5rem;
  margin: 1rem 0 0.5rem 0;
}
.iteminfo-container section {
  display: block;
}

.container {
  margin: auto;
  padding: 0 1rem;
  max-width: 75rem;
  width: 100%;
}
.iteminfo-container {
  margin: 1rem 10%;
}
  .product{
    display: flex;
  }
    .product-container{  
      max-width: 60%;
      width: 100%;
      margin: 0 auto;
    }
      .product-gallery{
        display: flex;
        width: auto;
        position: relative;
        top: 0px;
        margin-top: 30px;
      }
        .product-image {
          min-width: 250px;
          width: 100%;
        }
        .desktop{
          display: block;
        }
        .mobile{
          display: none;
        }
          .product-image img{
            object-fit: cover;
            width: 100%;
            height: auto;
          }
        .image-list {
          margin: 0 10px 0px 0;
          width: 13.9%;
        }
          .image-item {
            padding-bottom: 20px;
            width: 66px;
          }
            .image-item img {
              display: block;
              position: relative;
              width: 100%;
              height: auto ;
              transition: opacity 0.3s ease;
              cursor: pointer;
            }
    .info-container{
      text-align: center;
      flex: 0 0 50%;
      max-width: 40%;
      position: relative;
      width: 100%;
      
      padding-left: 20px;

    }
        .price{
          font-size: 30px;
          line-height: 40px;
          font-family: 'Archivo', sans-serif;
          font-weight: 500;
          color: #de1f26;
        }
        .item-information{
          margin-top: 33px;
          width: 100%;
          display: block;
          text-align: center;
        }
          .shipping{
            color: #de1f26;
            font-size: 14px;
            padding: 8px 12px 8px 0;
          }
          .email{
            color: #de1f26;
            font-size: 14px;
            margin-left: auto;
            padding: 8px 12px 8px 0;
          }
        .info-add-item{
          margin-top: 5px;
          display: block;
        }
        .description {
          margin: 1rem 0;
          text-align: center;
        }

.grid.menu > [class*="column-"] {
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.grid.product {
  padding: 0 0 1.5rem 0;
}
.grid.second-nav > [class*="column-"] {
  padding: 0.5rem 1rem;
}
footer {
  padding: 1rem 0;
  text-align: center;
}




@media (max-width: 1080px) {
  .desktop{
    display: none;
  }
  .mobile{
    display: block;
  }

  .iteminfo-container{

  }
    .product{
      display: block;
    }
      .product-container{
        max-width: 90%;
      }
        .product-gallery{
          top: 0 !important;
        }

  .info-container{
    margin: 10px auto;
    padding-left: 0;
    max-width: 100%;
  }
  .image-list{
    display: none;
  }
}

@media (max-width: 600px) {
  .desktop{
    display: none;
  }
  .mobile{
    display: block;
  }
  .bread-container{
    width: 95%;
  }
    .breadcrumb-list li{
      font-size: 0.7rem;
      letter-spacing: 0.05rem;
    }
  .iteminfo-container{
    margin: 1rem 0;
  }

  .info-container{
    margin: 10px auto;
    padding-left: 0;
    max-width: 100%;
  }
  .image-list{
    display: none;
  }
}
